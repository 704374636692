// TempData
import TempDataFooter from '../TempData_footer';

import image from '../../../assets/Images/CR_lokation_10.png';
import * as ColorGuide from '../../../cssGuides/colorGuide';

const Data = {
  pageTemplate: 'Template1',
  pageData: {
    level_1_Id: '0feb767f-239e-49c9-ac5d-31d9ad84fac8',
    pageTitle: 'Kampagne - mere værd',
    pageDescription: '',
    nav: {
      searchUrl: '/TestPages/SearchModule',
      loginUrl: '/betaLogin',
      menus: {
        subMenus: [
          {
            id: '0feb767f-239e-49c9-ac5d-31d9ad84fac8',
            name: 'Kampagne - mere værd',
            url: '/Kampagne---mere-vaerd',
            menuItems: [],
          },
        ],
      },
    },
    megaMenu: {
      megaMenu: [],
    },
    footer: TempDataFooter,
    bannerImage: image,
    bannerContent:
      '<h1 class="text-left">Læn dig trygt tilbage\n<br />\n og se din formue vokse</h1><h3 class="text-left colorWhite">Vi har nogle af de højeste afkast år efter år, så du kan roligt glæde dig til din pension.</h3>',
  },
  pageContent: [
    {
      componentName: 'BlockArea',
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2 class="colorWhite">Alt det der gør din pension mere værd</h2>',
          },
        },
        {
          componentName: 'Columns',
          componentChildren: [
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<table><tbody><tr><td><a href="/Kampagne---mere-vaerd/Hoeje-afkast"><img src="/media/96b70d24-ec07-46f1-b489-2fd45a1f7acc/mJUdZg/Billeder%202018/Kampagnesite%20mere%20værd/hoeje_afkast.png?mw=60" /></a> </td><td class="colorWhite"><h3 class="colorWhite"><br />  Høje afkast</h3><p class="colorWhite"> Hos os får du både tryghed og høje afkast.</p></td></tr><tr><td><a href="/Kampagne---mere-vaerd/Lave-omkostninger"><img src="/media/212ac4ce-197b-4771-aae3-d648a1c62424/EGlz7g/Billeder%202018/Kampagnesite%20mere%20værd/lave_omkostninger.png?mw=60" /></a> </td><td class="colorWhite"><h3 class="colorWhite"><br />  Lave omkostninger</h3><p class="colorWhite"> Med nogle af markedets laveste omkostninger får du op til en halv million ekstra til seniorlivet. </p></td></tr><tr><td><a href="/Kampagne---mere-vaerd/Raadgivning-naar-det-passer-dig"><img src="/media/a2c77f7e-0c1b-4551-8624-c37e87841a6c/oG3z5g/Billeder%202018/Kampagnesite%20mere%20værd/raadgivning_naar_det_passer_dig.png?mw=60" /></a> </td><td class="colorWhite"><h3 class="colorWhite"> Rådgivning når det passer dig</h3><p class="colorWhite"> Hvis du har brug for personlig rådgivning</p></td></tr></tbody></table><h2 class="colorDark"></h2><h2 class="colorDark"></h2><h2 class="colorDark"></h2>',
              },
            },
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<table><tbody><tr><td><a href="/Kampagne---mere-vaerd/Samme-fordele-hele-livet"><img src="/media/903da34b-73f6-4658-a6a2-ddbdb485c7b7/gdADGg/Billeder%202018/Kampagnesite%20mere%20værd/vaerdifulde_vaerktoejer.png?mw=80" /></a>  </td><td class="colorWhite"><h3 class="colorWhite"><a href="/Kampagne---mere-vaerd/Samme-fordele-hele-livet"> </a><br /><a href="/Kampagne---mere-vaerd/Samme-fordele-hele-livet"> </a> Samme fordele hele livet</h3><p class="colorWhite"> For os er det en selvfølge, at du har samme gode vilkår hele livet. </p><h3></h3></td></tr><tr><td><a href="/Kampagne---mere-vaerd/Vaerdifuld-raadgivning"><img src="/media/4e2a2d5b-781d-496d-b114-99ece5cfd8d6/ILtK8Q/Billeder%202018/Kampagnesite%20mere%20værd/samme_fordele_hele_livet.png?mw=80" /></a>  </td><td class="colorDark"><h3 class="colorWhite"><br />\r\n  Værdifulde værktøjer</h3><p class="colorWhite"> Kom hele vejen rundt om din økonomi - en pensionsplan inden din pension kan give dig mange tusinde kroner i ekstra opsparing.</p></td></tr><tr><td><a href="/Kampagne---mere-vaerd/Tryghed-omkring-dit-helbred"><img src="/media/8cdb2714-825c-41f7-b174-2307472fcda3/MdT4Dg/Billeder%202018/Kampagnesite%20mere%20værd/tryghed_omkring_dit_helbred.png?mw=80" /></a>  </td><td class="colorDark"><h3></h3><h3 class="colorWhite"> Tryghed omkring dit helbred</h3><p class="colorWhite"> Vi hjælper dig videre i livet på de bedste vilkår, hvis uheldet er ude.</p></td></tr></tbody></table>',
              },
            },
          ],
          componentData: {
            gap: '40',
            columns: 2,
          },
        },
      ],
      componentData: {
        backgroundColor: ColorGuide.aqua,
      },
    },
    {
      componentName: 'BlockArea',
      componentChildren: [
        {
          componentName: 'Columns',
          componentChildren: [
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<h2>"Vi valgte Sampension, fordi rigtig mange ting på værdisiden forbinder os."</h2><h3></h3><h3>Anders Mærsk, HR-chef i Albatros Travel</h3>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<div class="video-container embed-responsive-16by9"><iframe src="//www.youtube.com/embed/MpQ7mBMargU?fs=1&amp;rel=0" frameborder="0" width="430" height="250"></iframe></div>',
              },
            },
          ],
          componentData: {
            gap: '40',
            columns: 2,
          },
        },
      ],
      componentData: {
        backgroundColor: ColorGuide.white,
      },
    },
    {
      componentName: 'BlockArea',
      componentChildren: [
        {
          componentName: 'Columns',
          componentChildren: [
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<p><img src="/media/8dac6b60-f057-4e02-8ae9-ab9adc2c7dd5/MoZCsQ/Billeder%202018/Kampagnesite%20mere%20værd/oza.jpg" /></p><div class="sc-dVhcbM kCVUET"><div><div class="sc-fAjcbJ eIGljg"><h3><strong>Oshrit Zargarov, Pensionsrådgiver, Kunderådgivning og Salg</strong></h3><p>”Det, der driver mig, er den daglige kontakt med kunderne. Her er min vigtigste opgave at skabe tillid og tryghed omkring deres pension, så de får ro i maven.” - <em>Oshrit Zargarov, Pensionsrådgiver, Kunderådgivning og Salg</em></p></div> </div></div><div class="sc-dVhcbM kCVUET"><div class="sc-fyjhYU gDcBrG"><div class="sc-ugnQR fZmkAv"><div></div></div></div><div> </div></div>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<p><img src="/media/93745eb2-f55e-4fc6-8a33-574ff785b77f/Uvl2vA/Billeder%202018/Kampagnesite%20mere%20værd/kab.jpg" /></p><div class="sc-dVhcbM kCVUET"><div><div class="sc-fAjcbJ eIGljg"><h3><strong>Karina Bakke Jørgensen, Teamleder, Kundeservice</strong></h3><p>”Hvis vores kunder har nogle udfordringer, skal vi være der for dem og løse tingene hurtigst muligt. Som kundeejet selskab er vi til for dem – ikke omvendt.” - <em>Karina Bakke Jørgensen, Teamleder, Kundeservice</em></p></div></div></div><div class="sc-dVhcbM kCVUET"><div><div class="sc-fAjcbJ eIGljg"></div></div></div>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<p><img src="/media/757f2a81-28f5-47d0-a9aa-4408e94f7299/oj3AuA/Billeder%202018/Kampagnesite%20mere%20værd/jwi.jpg" /></p><div class="sc-dVhcbM kCVUET"><div><div class="sc-fAjcbJ eIGljg"><h3><strong>Jacob Westi Ingvarsen, Pensionsrådgiver, Skadesafdeling</strong></h3><p>”I vores afdeling går vi langt for, at kunderne ikke skal føle sig som endnu et nummer i rækken. Det er en stor tilfredsstillelse at gøre en forskel, som for alvor kan mærkes for den enkelte. Det betyder meget for mig.” - <em>Jacob Westi Ingvarsen, Pensionsrådgiver, Skadesafdeling</em></p></div></div></div>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
          ],
          componentData: {
            gap: '40',
            columns: 3,
          },
        },
      ],
      componentData: {
        backgroundColor: ColorGuide.aqua,
      },
    },
    {
      componentName: 'BlockArea',
      componentChildren: [
        {
          componentName: 'Columns',
          componentChildren: [
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<h2 class="text-left">Mere værd</h2><p>Som kundeejet virksomhed er det vores vigtigste opgave at styrke din formue – ikke Sampensions egen. Helt konkret kan det betyde op til en halv million ekstra, når du går på pension. Og det er ikke bare noget, der kan mærkes på kontoen, men også i sindet: Når du ved, at dine penge er i trygge hænder, kan du slippe bekymringerne.</p><p>Pension handler ikke kun om fremtiden – det handler også om her og nu. Derfor sikrer vi din indtægt, hvis du mister dit arbejde, ligesom vi hjælper dig videre, hvis uheldet er ude. Og når du får brug for personlig rådgivning, sidder vores erfarne rådgivere klar til at hjælpe, også uden for almindelig åbningstid.</p><p>Det er alle de ting, som gør din pension mere værd. Både målt i tusindkronesedler og i den ro, der sænker sig, når du ved, at der er maksimalt styr på forvaltningen af dine penge og på at sikre dit helbred. Det er derfor, vi siger: </p><p><strong>Du kan roligt glæde dig til din pension.</strong></p><p>Adm. direktør, Hasse Jørgensen</p>',
              },
            },
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<p><img src="/media/1f7b1b4b-5109-4fe6-a3c3-8f324e5131f3/EDZofQ/Billeder%202018/Kampagnesite%20mere%20værd/Hasse%20Jorgensen.jpg" /></p>',
              },
            },
          ],
          componentData: {
            gap: '40',
            columns: 2,
          },
        },
      ],
      componentData: {
        backgroundColor: ColorGuide.white,
      },
    },
    {
      componentName: 'BlockArea',
      componentChildren: [
        {
          componentName: 'Columns',
          componentChildren: [
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<p><img src="/media/cdb352ef-a890-4f47-9185-a769ea382bde/ktggtg/Billeder%202018/Kampagnesite%20mere%20værd/phr.jpg" /></p><h3><strong>Philip Rostgaard, Pensionsrådgiver, Kundeservice</strong></h3><p>”Det er en god følelse at arbejde med pension i et kundeejet selskab. Det giver meget mening for mig, simpelthen fordi det er mest fair over for pensionsopsparerne.” - <em>Philip Rostgaard, Pensionsrådgiver, Kundeservice</em></p>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<img src="/media/7a3bb7cc-f779-46ee-aad3-15cb874e0ba8/Eq-FzA/Billeder%202018/Kampagnesite%20mere%20værd/tco.jpg" /><h3><strong>Thomas Corval, Kunderelationschef, Markedsafdeling</strong></h3><p>”Det vigtigste i mit job er fortsat at styrke de langvarige partnerskaber. Vi er skabt som kundeejet selskab i 1945. Derfor ligger det i vores DNA at føre åbne og progressive dialoger, som virksomhederne og organisationerne vurderer er værdifulde for dem.” - <em>Thomas Corval, Kunderelationschef, Markedsafdelingen</em></p>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
            {
              componentName: 'TextArea',
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html:
                      '<img src="/media/15b1834a-8194-4091-8e46-f30686d3c6a1/4mtyow/Billeder%202018/Kampagnesite%20mere%20værd/all.jpg" /><h3><strong>Anne-Louise Lindkvist, Kunderådgivningschef, Kunderådgivning og Salg</strong></h3><p>”For mig betyder ”Mere værd” den tryghed, som kunderne oplever, når de kan se, at der bliver taget godt hånd om deres opsparede penge.”- <em>Anne-Louise Lindkvist, Kunderådgivningschef, Kunderådgivning og Salg</em></p>',
                  },
                },
              ],
              componentData: {
                color: ColorGuide.white,
              },
            },
          ],
          componentData: {
            gap: '40',
            columns: 3,
          },
        },
      ],
      componentData: {
        backgroundColor: ColorGuide.aqua,
      },
    },
    {
      componentName: 'BlockArea',
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html: '<h2>Læs mere om dine fordele</h2>',
          },
        },
        {
          componentName: 'Columns',
          componentChildren: [
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<h4><a href="/Kampagne---mere-vaerd/Hoeje-afkast">Høje afkast\r\n<br /><br /></a></h4><h4><a href="/Kampagne---mere-vaerd/Lave-omkostninger">Lave omkostninger\r\n<br /><br /></a></h4><h4><a href="/Kampagne---mere-vaerd/Raadgivning-naar-det-passer-dig">Rådgivning når det passer dig</a></h4>',
              },
            },
            {
              componentName: 'HtmlRender',
              componentData: {
                html:
                  '<h4><a href="/Kampagne---mere-vaerd/Samme-fordele-hele-livet">Samme fordele hele livet\r\n<br /><br /></a></h4><h4><a href="/Kampagne---mere-vaerd/Vaerdifuld-raadgivning">Værdifuld rådgivning\r\n<br /><br /></a></h4><h4><a href="/Kampagne---mere-vaerd/Tryghed-omkring-dit-helbred">Tryghed omkring dit helbred</a></h4>',
              },
            },
          ],
          componentData: {
            gap: '40',
            columns: 2,
          },
        },
      ],
      componentData: {
        backgroundColor: ColorGuide.white,
      },
    },
  ],
};

export default Data;
